<template>

	<main class="container">
		
		<div id="app">
			<!--popup-Open></popup-Open-->
			<!--<transition name="fade" mode="out-in">
				<router-view name="rv-container" :key="$route.fullPath"></router-view>
			</transition>-->
			<!-- <router-view name="rv-container" :key="$route.fullPath"></router-view> -->
		</div>
		<div class="content">
			<div class="main_visual">
				<div class="visual_wrap">
					<ul class="swiper-wrapper">
						<li class="silde_item swiper-slide">
							<div class="inner" style="background-image: url(/wapu/assets/img/main/img_visual_01.png);">
								<div class="text_wrap">
									<div class="tit">
										<span class="ko">안정적인 기술로 비지니스 성장을 지원하는</span>
										<strong class="en">Digital<br> Transformation.</strong>
									</div>
									<div class="desc">
										WAPU의 간단한 코드만으로 쉽고 빠른 서비스를 제공합니다.
									</div>
								</div>
							</div>
						</li>
						<li class="silde_item swiper-slide">
							<div class="inner" style="background-image: url(/wapu/assets/img/main/img_visual_02.png);">
								<div class="text_wrap">
									<div class="tit">
										<span class="ko">정확한 성과 측정을 위한</span>
										<strong class="en">Data<br> Analytics.</strong>
									</div>
									<div class="desc">
										WAPU는 탁월한 분석 능력과 역량으로 다양한 레포트를 구성해드립니다.
									</div>
								</div>
							</div>
						</li>
						<li class="silde_item swiper-slide">
							<div class="inner" style="background-image: url(/wapu/assets/img/main/img_visual_03.png);">
								<div class="text_wrap">
									<div class="tit">
										<span class="ko">여러분께 여유시간을 제공해드립니다.</span>
										<strong class="en">WAPU<br> System.</strong>
									</div>
									<div class="desc">
										보고서 만들 시간이 부족한 실무자, 지표 자료를 검토하시는 관리자에게 추천합니다.
									</div>
								</div>
							</div>
						</li>
					</ul>
					<div class="swiper-control">
						<div class="swiper-button-prev"></div>
						<div class="swiper-pagination"></div>
						<div class="swiper-button-next"></div>
					</div>
				</div>
			</div>
			<div class="main_started">
				<div class="inner">
					<h2 class="main_title"><span>WAPU</span> 시작하기</h2>
					<div class="started_apply">
						<div class="apply_item">
							<div class="text_wrap">
								<strong class="tit">잠들어있는 <b>데이터를 분석</b>하여<br> <b>인사이트</b>를 얻으세요.</strong>
								<p class="desc">무료 체험 해보세요! 계정별로 1회 사용해볼 수 있습니다.</p>
							</div>
							<a href="/service/info" class="btn_apply">서비스 신청하기</a>
						</div>
					</div>
					<div class="started_manual">
						<div class="manual_item lt">
							<p class="text_wrap">
								<b>구글 계정 생성</b>은 무료이며<br>생성 후 <b>회원가입</b>할 수 있습니다.
							</p>
						</div>
						<div class="manual_item rt">
							<p class="text_wrap">
								<b>회원가입</b> 후<br>원하시는 <b>서비스를 신청</b>하세요.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="main_news">
				<div class="inner">
					<h2 class="main_title"><span>WAPU</span> 소식</h2>
					<div class="tabs">
						<ul class="tab_menu">
							<li class="tab_item" @click="setBoardKey('cms_notice')">
								<button type="button">공지사항</button>
								<a href="/board/cms_notice" class="btn_more"><span class="blind">더보기</span></a>
							</li>
							<li class="tab_item" @click="setBoardKey('cms_faq')">
								<button type="button">FAQ</button>
								<a href="/faq" class="btn_more"><span class="blind">더보기</span></a>
							</li>
						</ul>

						<div class="tab_content">

							<div class="board_notice tab_panel">
								<ul class="board_list swiper-wrapper">
									<li class="board_item swiper-slide" v-for="(item, index) in boardList" :key="'b-'+ index">
										<template v-if="boardKey == 'cms_notice'">
											<a :href="'/board/cms_notice/view?idx='+ item.idx"  >
												<span class="cate">공지사항</span>
												<strong class="tit">{{item.title}}</strong>
												<p class="desc">{{item.contentText}}</p>
												<span class="date">{{item.createDate | dateFilter}}</span>
											</a>
										</template>
									</li>
								</ul>
							</div>

							<div class="board_news tab_panel">
								<ul class="board_list swiper-wrapper">
									<li class="board_item swiper-slide" v-for="(item,index) in boardList" :key="'b2-'+ index">

										<a :href="'/faq?target='+item.idx">
											<span class="cate">FAQ</span>
											<strong class="tit">{{item.title}}</strong>
											<p class="desc">{{item.contentText}}</p>
											<span class="date">{{item.createDate | dateFilter}}</span>
										</a>
									</li>
								</ul>
							</div>


						</div>

					</div>
				</div>
			</div>
		</div>
	</main>

</template>

<script>
import $ from "jquery";
// import popupOpen from "@/components/display/popup";

export default {
	name: "index",
	// components: {popupOpen},
	data() {

		return {
			userVO: {},
			rateCode: 0,

			mainBanners: [],
			subBanners: [],
			eduMainList: [],

			popupList: [],

			requestEduList: [],

			boardList: [],
			boardKey: 'cms_notice'
		}
	},
	created() {
		// this.getAccountRateCode()
		// this.getRequestEduList();
		// this.getMainData();
		// this.recentBoardList();
		// this.getUsePopupList();

		// window.addEventListener('resize', this.handleResize);
	},
	mounted() {

		this.getBoardList();


	},
	watch: {},
	updated() {
		/*this.$nextTick(() => {
			import('@/../public/assets/js/common-ui')
			this.handleResize();
		})*/
	},
	methods: {
		goPage() {
			this.$router.push("/service/info")
		},
		setBoardKey(boardKey){
			this.boardKey = boardKey
			this.getBoardList()
		},
		getBoardList() {

			this.$eventBus.$emit("startProgress")

			// this.params.mode = "list";
			var temp = JSON.parse(JSON.stringify(this.$route.params))
			// var temp = JSON.parse(JSON.stringify(this.params))
			temp.size = this.size || 4
			temp.page = this.page || 0
			temp.boardKey = this.boardKey || 'cms_notice'
			//

			this.axiosCaller.get(this, this.APIs.BOARDS + "/getBoardList", temp, (res) => {
				var result = res.data
				console.log(result, "====>")
				if (result.status === "ok") {
					if (result.boardInfoVO.isNologin && !this.isLogin()) {
						this.requireLogin(this, false)
					}
					this.boardCount = result.boardMap.count
					//this.boardList.push(...result.boardMap.list)
					this.boardList = result.boardMap.list
					this.noticeList = result.noticeMap.list
					this.boardInfo = result.boardInfoVO

					let to = localStorage.getItem("to")
					let prevIdx = localStorage.getItem("prevIdx")
					if (to) {
						this.$nextTick(() => {window.scroll(0, to)})
						localStorage.removeItem("to")
					}
					if (prevIdx) {
						this.$nextTick(() => {
							this.webUtils.scrollTo("#item-" + prevIdx)
						})
						localStorage.removeItem("prevIdx")
					}

				} else {
					this.swalUtils.gritter("Error", result.msg, "error")
				}
				this.$eventBus.$emit("doneProgress")
			})
		},

		handleResize() {
			// $('.main-ongoing').find('.swiper-slide').css('width', `${$('.main-update').find('.swiper-slide').css('width')}`)
			// $('.main-ongoing').find('.swiper-slide').css('margin-right', `${$('.main-update').find('.swiper-slide').css('margin-right')}`)
			$('.main-latest').find('.desc').css('-webkit-line-clamp', `${(Math.ceil($('.main-latest').find('.thumb-box').eq(0).css('height').replace(/[^0-9, .]/g, "")) > 214 ? 4 : 3)}`)
		},


		goBoardView(_boardKey, _bidx) {
			this.webUtils.goTo(this, '/app/board/' + _boardKey + '/?idx=' + _bidx)
		},


		goLearning(_emIdx) {
			this.webUtils.goTo(this, '/app/education/main/?mode=learn&emIdx=' + _emIdx)
		},


	},
}
</script>

<style scoped>

</style>
